import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@emotion/react';
import { Global, css } from '@emotion/core';

import { RouteGuard } from './components/route-guard';
import { PageLayout } from './components/page-layout';
import { RolePage } from './pages/role-page';
import { Calls } from './pages/page-calls';
import { Home } from './pages/page-home';

import { cascadedRoles } from './auth-config';

import { darkTheme, lightTheme } from './theme';

const Pages = () => {
  return (
    <Routes>
      <Route
        path="/calls"
        element={
          <RouteGuard roles={cascadedRoles.STAFF}>
            <Calls />
          </RouteGuard>
        }
      />
      <Route
        path="/staff"
        element={
          <RouteGuard roles={cascadedRoles.STAFF}>
            <RolePage role="Staff" />
          </RouteGuard>
        }
      />
      <Route
        path="/product"
        element={
          <RouteGuard roles={cascadedRoles.PRODUCT}>
            <RolePage role="Product" />
          </RouteGuard>
        }
      />
      <Route
        path="/developer"
        element={
          <RouteGuard roles={cascadedRoles.DEVELOPER}>
            <RolePage role="Developer" />
          </RouteGuard>
        }
      />
      <Route
        path="/admin"
        element={
          <RouteGuard roles={cascadedRoles.ADMIN}>
            <RolePage role="Admin" />
          </RouteGuard>
        }
      />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const App = ({ instance }: { instance: any }) => {
  const [darkmode, setDarkmode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    if (darkmode) {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, [darkmode]);

  return (
    <MsalProvider instance={instance}>
      <Global
        styles={css`
          body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
              'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: ${theme.colors.background};
            color: ${theme.colors.text};
          }

          code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
          }

          td:first-child {
            font-weight: bold;
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <PageLayout toggleDarkmode={() => setDarkmode(!darkmode)}>
          <Pages />
        </PageLayout>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
