import styled from '@emotion/styled';
import React from 'react';

import { createClaimsTable } from '../utils/claim-utils';

const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Table = styled.table`
  max-height: 37rem;
  max-width: 1300px;
  border: 1px solid ${(props) => props.theme.colors.tableBorder};
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid ${(props) => props.theme.colors.tableBorder};
  border-collapse: collapse;
  text-align: start;
  padding: 8px;
`;

const Tr = styled.tr<{ rowId: number }>`
  background-color: ${(props) =>
    props.rowId % 2 === 1 ? props.theme.colors.tableOddRowBackground : props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.tableBorder};
  border-collapse: collapse;
`;

const Td = styled.td`
  word-break: break-all;
  border: 1px solid ${(props) => props.theme.colors.tableBorder};
  border-collapse: collapse;
  padding: 8px;
`;

export const IdTokenData = (props: { idTokenClaims: Record<string, any> }) => {
  const tokenClaims = createClaimsTable(props.idTokenClaims);
  const tableRow = Object.keys(tokenClaims).map((key, i) => {
    return (
      <Tr key={key} rowId={i}>
        {tokenClaims[key].map((claimItem) => (
          <Td key={`${claimItem}`} className="tableColumn">
            {claimItem}
          </Td>
        ))}
      </Tr>
    );
  });
  return (
    <>
      <Data className="data-area-div">
        <p>
          <strong> ID token Claims </strong>
          For more information about ID token Claims please visit the following page:{' '}
          <span>
            <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
              Claims in an ID token
            </a>
          </span>
        </p>
        <div className="data-area-div table">
          <Table>
            <thead>
              <tr>
                <Th>Claim</Th>
                <Th>Value</Th>
                <Th>Description</Th>
              </tr>
            </thead>
            <tbody>{tableRow}</tbody>
          </Table>
        </div>
      </Data>
    </>
  );
};
