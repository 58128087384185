import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './navigation-bar';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-template-areas:
    'header header  header'
    'content content content'
    'content content content'
    'footer footer footer';
  gap: 20px;
  margin-bottom: 50px;
`;

const Page = styled.div`
  grid-column: col-start / span 12;
  grid-row: 2;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.featureBackground};
  color: ${(props) => props.theme.colors.featureBackgroundContrast};
  font-size: small;
  text-align: center;
`;

type TProps = PropsWithChildren & {
  toggleDarkmode: () => void;
};

export const PageLayout = (props: TProps) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <Container>
      <NavigationBar toggleDarkmode={props.toggleDarkmode} />
      <Page>
        <h5>
          <center>Loop Support App</center>
        </h5>
        {props.children}
      </Page>
      <AuthenticatedTemplate>
        <Footer>
          <center>
            Drink more beer.&nbsp;
            <a href="https://innerwestaletrail.com.au/" target="_blank" rel="noreferrer">
              It's good for you! 🍻
            </a>
          </center>
        </Footer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5>
          <center>Please log in above.</center>
        </h5>
      </UnauthenticatedTemplate>
    </Container>
  );
};
