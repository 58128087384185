import React from 'react';

import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { callStaffEndpoint } from '../fetch';
import { protectedResources } from '../auth-config';
import styled from '@emotion/styled';

const PageContent = styled.div`
  margin: 10px;
  max-width: 1300px;
  margin: auto;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.featureBackgroundContrast};
  background-color: ${(props) => props.theme.colors.featureBackground};
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-weight: 700;
  &:hover {
    background-color: ${(props) => props.theme.colors.featureBackground2};
    color: ${(props) => props.theme.colors.featureBackgroundContrast2};
  }
`;

export const Calls = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [response, setResponse] = React.useState('');

  const callStaff = async () => {
    setResponse('');
    const res = await callStaffEndpoint(protectedResources.api.staff);
    setResponse(res);
  };

  const callProduct = async () => {
    setResponse('');
    const res = await callStaffEndpoint(protectedResources.api.product);
    setResponse(res);
  };
  const callDeveloper = async () => {
    setResponse('');
    const res = await callStaffEndpoint(protectedResources.api.developer);
    setResponse(res);
  };
  const callAdmin = async () => {
    setResponse('');
    const res = await callStaffEndpoint(protectedResources.api.admin);
    setResponse(res);
  };

  return (
    <PageContent>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <>
            <p>
              User: {activeAccount.idTokenClaims?.preferred_username} ({activeAccount.name})
            </p>
            <p>Roles: {activeAccount.idTokenClaims?.roles}</p>
            <p>
              <Button onClick={callStaff}>Call Staff Check</Button>
            </p>
            <p>
              <Button onClick={callProduct}>Call Product Check</Button>
            </p>
            <p>
              <Button onClick={callDeveloper}>Call Developer Check</Button>
            </p>
            <p>
              <Button onClick={callAdmin}>Call Admin Check</Button>
            </p>
            <p>Response: {response}</p>
          </>
        ) : null}
      </AuthenticatedTemplate>
    </PageContent>
  );
};
