import React from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth-config';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

const Nav = styled.nav`
  display: grid;
  grid-column: col-start / span 12;
  grid-row: 1;
  background-color: ${(props) => props.theme.colors.featureBackground};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const NavRow = styled.ul<{ count: number }>`
  display: grid;
  margin: 0;
  padding: 0;
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.featureBackgroundContrast};
  text-align: center;
  text-decoration: none;
  padding: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const DarkmodeToggle = styled.button`
  grid-column: col-start 10 / span 1;
  grid-row: 1;
  color: ${(props) => props.theme.colors.featureBackgroundContrast2};
  background-color: ${(props) => props.theme.colors.featureBackground2};
  margin-left: auto;
  border-radius: 5px;
  margin-right: 10px;
  border: none;
  padding: 10px;
  font-weight: 700;
  &:hover {
    background-color: ${(props) => props.theme.colors.featureBackgroundContrast2};
    color: ${(props) => props.theme.colors.featureBackground2};
  }
`;

const LoginButton = styled.button`
  grid-column: col-start 11 / span 1;
  grid-row: 1;
  color: ${(props) => props.theme.colors.featureBackgroundContrast2};
  background-color: ${(props) => props.theme.colors.featureBackground2};
  margin-left: auto;
  border-radius: 5px;
  margin-right: 10px;
  border: none;
  padding: 10px;
  font-weight: 700;
  &:hover {
    background-color: ${(props) => props.theme.colors.featureBackgroundContrast2};
    color: ${(props) => props.theme.colors.featureBackground2};
  }
`;

const activeStyle = {
  fontWeight: 700,
};

type TNavLinks = { path: string; display: string }[];

const authedLinks: TNavLinks = [
  {
    path: '/',
    display: 'Home',
  },
  {
    path: '/staff',
    display: 'Staff',
  },
  {
    path: '/product',
    display: 'Product',
  },
  {
    path: '/developer',
    display: 'Developer',
  },
  {
    path: '/admin',
    display: 'Admin',
  },
  {
    path: '/calls',
    display: 'Calls',
  },
];

const unauthedLinks: TNavLinks = [
  {
    path: '/',
    display: 'Home',
  },
];

type TProps = {
  toggleDarkmode: () => void;
};

export const NavigationBar = (props: TProps) => {
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };
  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <AuthenticatedTemplate>
        <Nav>
          {authedLinks.map(({ path, display }) => (
            <StyledNavLink to={path} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
              {display}
            </StyledNavLink>
          ))}
          <DarkmodeToggle onClick={props.toggleDarkmode}>🌞 / 🌙</DarkmodeToggle>
          <LoginButton onClick={handleLogoutRedirect}>
            Sign out ({activeAccount ? activeAccount.name : 'Unknown'})
          </LoginButton>
        </Nav>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Nav>
          <NavRow count={unauthedLinks.length + 1}>
            {unauthedLinks.map(({ path, display }) => (
              <StyledNavLink to={path} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                {display}
              </StyledNavLink>
            ))}
            <LoginButton onClick={handleLoginRedirect}>Sign in</LoginButton>
          </NavRow>
        </Nav>
      </UnauthenticatedTemplate>
    </>
  );
};
