// loop test 2 play account
// export const tenantID = 'd483167f-f7c4-40e2-b0d1-ef232f354fd6'; // tenant of the smokeball SSO
// export const clientID = '6cb19920-198c-4548-a3f5-77d3df897b9c'; // client ID of the app in the tenancy

export const tenantID = 'fcb51389-52a5-4f13-a195-fcc809775f09'; // tenant of the smokeball SSO
export const clientID = '7fdc75fa-671f-4e12-a5d2-6d00e3edf80c'; // client ID of the app in the tenancy

export const cascadingSuppRoles = [
  'Loop.Supp.Staff',
  'Loop.Supp.Product',
  'Loop.Supp.Developer',
  'Loop.Supp.Admin',
] as const;

export const appRoleDelegatedPermission = 'access_via_approle_assignments';
