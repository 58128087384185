import React, { useState, useEffect } from 'react';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest } from '../auth-config';

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard = ({ ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const currentAccount = instance.getActiveAccount();

  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    const onLoad = async () => {
      if (currentAccount && currentAccount.idTokenClaims && currentAccount.idTokenClaims['roles']) {
        const intersection = props.roles.filter((role: string) =>
          currentAccount.idTokenClaims!['roles']!.includes(role),
        );

        setIsAuthorized(intersection.length > 0);
      } else {
        setIsAuthorized(false);
      }
    };

    onLoad().catch((e) => {
      console.error('Error fetching currentAccount claims');
      console.error(e);
    });
  }, [instance, currentAccount, props.roles]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
      {isAuthorized ? (
        <div>{props.children}</div>
      ) : (
        <div className="data-area-div">
          <h3>
            You are unauthorized to view this content. You require one of the following roles: [{props.roles.join(' ,')}
            ]
          </h3>
          <p>
            Your roles: [
            {currentAccount && currentAccount.idTokenClaims && currentAccount!.idTokenClaims!['roles']
              ? currentAccount.idTokenClaims['roles'].join(' ,')
              : 'none'}
            ]
          </p>
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
};
