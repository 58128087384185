import React from 'react';

import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';

const PageContent = styled.div`
  margin: 10px;
  max-width: 1300px;
  margin: auto;
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.colors.highlight};
  background-color: ${(props) => props.theme.colors.highlightBackground};
  padding-left: 2px;
  padding-right: 2px;
`;

export const RolePage = ({ role }: { role: string }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  return (
    <PageContent>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <div>
            <p>
              User: <code>{activeAccount.idTokenClaims?.preferred_username}</code> ({activeAccount.name})
            </p>
            <p>
              Roles: <code>[{activeAccount.idTokenClaims?.roles?.join(',')}]</code>
            </p>
            <p>
              Your roles permit you to see the <Highlight>{role}</Highlight> page.
            </p>
          </div>
        ) : null}
      </AuthenticatedTemplate>
    </PageContent>
  );
};
